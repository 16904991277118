$primaryMain: #1D70B8;
$primaryLight: #018ACA;
$primaryDark: #002E5F;
$secondaryMain: #605E5C;
$secondaryLight: #BBC3C2;
$secondaryDark: #323130;
$snowColor:#f5f5f560;

//Accent color
$accent: #F49600;

//Resolution
$mobile_width: 400px;