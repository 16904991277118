@import "Initials.scss";

.he-svg-icon {
  &.disabled {
    fill: $secondaryMain !important;
    cursor: auto;
  }
  &:hover {
    fill: $primaryDark;
  }
}

.svg-accent {
  fill: $accent !important;
}

.svg-primary {
  fill: $primaryDark !important;
}

.svg-secondary {
  fill: $secondaryMain !important;
  &:hover {
    fill: $secondaryDark !important;
  }
}

.svg-snow {
  fill: $snowColor !important;
}

.svg-pointer {
  cursor: pointer;
}

.icon-custom-info {
  background: $accent;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: bold;
  &.click:hover {
    cursor: pointer;
  }
}