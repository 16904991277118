@import "Initials.scss";

.st-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 16px;
  box-sizing: border-box;
  max-height: calc(100% - 32px);
  outline: none;
  &.small {
    max-width: min(400px, calc(100vw - 32px));
    width: 100%;
  }
  &.medium {
    max-width: min(800px, calc(100vw - 32px));
    width: 100%;
  }
  &.large {
    max-width: min(1200px, calc(100vw - 32px));
    width: 100%;
  }
  .st-modal-body {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.MuiListItem-root.active {
  background: #{$secondaryLight + "50"};
}

.mes-measures-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: -20px !important;
  > * {
    margin:20px;
  }
}

.mes-measures-box {
  padding: 20px;
  width: 200px;
  border-radius: 10px;
  &.blue {
    color: #1971c2;
    border: 2px solid #1971c2;
    background-color: #a5d8ff63;
    &:hover {
      background-color: #a5d8ff98;
    }
  }
  &.green {
    color: #2f9e44;
    border: 2px solid #2f9e44;
    background-color: #b2f2bb42;
    &:hover {
      background-color: #b2f2bb76;
    }
  }
  &.pointer {
    cursor: pointer;
  }
  .mes-measures-box-value {
    font-size: 3rem;
  }
  .mes-measures-box-title {
    font-size: 1.5rem;
  }
}