@import "Initials.scss";

$mobile-width: 650px;
.iframe-layout-container {
  min-height: 100vh;
  overflow: auto;
  &.bg {
    background: rgb(0 0 0 / 80%);
  }
  .iframe-layout-content-outside {
    margin: auto;
    @media (max-width: $mobile-width) {
      width: 100%;
    }
    .iframe-layout-content {
      background: white;
      margin: 0.5rem;
      max-width: 600px;
      @media (min-width: $mobile-width) {
        padding: 1rem;
      }
      @media (max-width: $mobile-width) {
        padding: 0.5rem;
      }
    }
  }
}

$circle-color: #e74c3c;

.iframe-canvas-wrapper {
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .iframe-canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .canvas-points-distance {
    position: absolute;
    z-index: 3;  
    border-radius: 2px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    color: white;
    background: #605E5C;
  }
  .canvas-point {
    position: absolute;
    z-index: 4;  
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    .canvas-point-inner {
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      pointer-events: none;
      z-index: 9999;
      background-color: $circle-color;
    }
    .MuiSvgIcon-root {
      stroke: #{$circle-color}80;
      fill: transparent;
      stroke-width: 0.5;
    }
  }
  .canvas-points-help-text {
    position: absolute;
    z-index: 4;  
    border-radius: 2px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    background: #ffffff70;
    color: $circle-color;
    .pulse {
      animation: pulse-point 2s infinite;
    }
  }
}

@keyframes pulse-point {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #{$circle-color}80;
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
