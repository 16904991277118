::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #8888885e;
}

::-webkit-scrollbar-thumb:hover {
    background: #555555a6;
}