@import "Initials.scss";

.he-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #25252510;
  z-index: 9999;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  &>span {
    display: block;
    margin: 0 auto;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    z-index: 999;
    &>span {
      background-color: $secondaryMain;
    }
  }
}